const Images = {
    logo: 'images/Logo.png',
    menu: 'images/menu.svg',
    logoBottom : 'images/logo_bottom.png',
    fbIcon: 'images/fb.png',
    bg1: 'images/bg1.png',
    bg2: 'images/bg2.png',
    CHAR: 'images/CHAR.png',
    company: 'images/company.png',
    bgGame: 'images/bgGame.png',
    bgAbout: 'images/bgAbout.jpg',
    work1 : 'images/1.jpg',
    work2 : 'images/2.jpg',
    work3 : 'images/3.jpg',
    work4 : 'images/4.jpg',
    submit: 'images/Vector.png',
    clientBg: 'images/client.png',
    ironsource: 'images/ironsource.png',
    mintegrallogo: 'images/mintegrallogo.png',
    bgEllipse1: 'images/Ellipse 14.png',
    bgEllipse2: 'images/Ellipse 15.png'
}
export default Images