export const GamesList = [
   {
        id:1,
        titleH: 'COMIC STICK',
        title: 'Comic Stick',
        detail: 'The newest Heroes game—Create a new hero with unimaginable powers and abilities by selecting and merging heroes',
        imgLarge:'images/top1.png',
        imgSmall:'images/game1.png',
        notop: 0,
    },

   {
        id:2,
        titleH: 'THIEVES GAME',
        title:'Thieves Game',
        detail:'Thieves Game: Thieves Game is the best option if you are looking for a real robbery shooting game with intends to heist the bank.',
        imgLarge:'images/top2.png',
        imgSmall:'images/game2.png' ,
        notop: 0,    
    },
    {
        id:3,
        titleH: 'MERGE SUPERHERO',
        title:'Merge SuperHero',
        detail:'In the world of Merge Superhero, discover all the parts needed to create superheroes and mysterious powers!',
        imgLarge:'images/top3.png',
        imgSmall:'images/game3.png',
        notop: 0,    
    },
    {
        id:4,
        titleH: 'TRAFFIC JAM',
        title:'Traffic Jam',
        detail:' Using your amazing strategy to solve and clear up the parking jam right now!',
        imgLarge:'images/top4.png',
        imgSmall:'images/game4.png', notop: 0,    },
    {
        id:5,
        titleH: 'META FIGHT',
        title:'Meta Fight',
        detail:'Customize your own superheroes with unique ideas on trend and get ready for the ultimate battle.',
        imgLarge:'images/top5.png',
        imgSmall:'images/game5.png' ,
        notop: 0,    
    },
    {
        id:6,
        titleH: 'META DANCE',
        title:'Meta Dance',
        detail:'Dancing your way to victory and hone abilities such as the ability to express emotions, interact with and perceive music, and cultivate reflexes.',
        imgLarge:'images/top6.png',
        imgSmall:'images/game6.png',
        notop: 1,    
    }
]



