import {React, useEffect} from "react";
import './Company.css'

function CompanyPage() {
    useEffect(() => {
        document.title = 'Company - Funnii';
    });

    return (
        <div className="CompanyPage block">
            <h2>FUNNII GAMES TERMS OF USE</h2>
            <div className="tyJCtd mGzaTb Depvyb baZpAe"><p dir="ltr" className="CDt4Ke zfr3Q">We are <b>Funnii</b>, a company
                under the laws of Vietnam, with registered address at: 658/69 Cach Mang Thang 8 street, Ward 11,
                District 3, HCMC, Vietnam (“Funnii” or “FN” or “We” or “Us”), is a developer and publisher of games and
                mobile game applications that are made available via app stores including the Apple Application Store
                and Google Play Store (our “Games”).</p><p dir="ltr"
                                                                                                         className="CDt4Ke zfr3Q">As
                a precondition for you using the Services, you must agree to these Terms of Service (“Agreement”). By
                using or otherwise accessing the Services, you agree to the Agreement. If you do not agree to the
                Agreement, you may not use or otherwise access the Services.</p><p dir="ltr" className="CDt4Ke zfr3Q">
                <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Unless otherwise specified by a component of the
                applicable Service, the Services are free to use or download but may contain features which may allow
                you to make purchases within the Services.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>1.
                About</p><p dir="ltr" className="CDt4Ke zfr3Q">By
                downloading one of our Games, you accept this Agreement and our Privacy Policy. Your relationship with
                <b>Funnii</b> will be subject to these and only this Agreement and our Privacy Policy. This Agreement and our
                Privacy Policy are available for download on the <b>Funnii</b> website www.playfun-ltd.com.</p><p dir="ltr"
                                                                                                           className="CDt4Ke zfr3Q">
                <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Each time you download one of our Games to your device a
                new Agreement is concluded and its terms apply to your use of this Game. By using or otherwise accessing
                the Services, you agree to the Agreement. If you do not agree to this Agreement and our Privacy Policy,
                you may not use or otherwise access the Services.</p><p
                dir="ltr" className="CDt4Ke zfr3Q">Application stores and platforms may provide their own terms that
                apply to your relationship with these app stores and platforms.</p><p dir="ltr"
                                                                                      className="CDt4Ke zfr3Q"><br/></p>
                <p dir="ltr" className="CDt4Ke zfr3Q">You represent that you are 13 years or older. If you are younger
                    than 18 years, you can only download our Games and play them on your device, if your parent(s) or
                    legal guardian have/has reviewed this Agreement and allowed you to download our Game o and play our
                    Game subject to this Agreement. <b>Funnii</b> may require adequate proof of your identity and age and
                    consent from a parent or guardian at any time.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q">You represent that you are accessing our Games as a private
                    person. Any commercial use of our Games is not allowed.</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2. End-User License Agreement</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.1 License</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Subject to the terms of this Agreement, FN grants you
                    a non-transferable, non-exclusive, license to (a) use for your personal use, and (b) copy for the
                    purpose of downloading, installing and executing the number of copies for which you are authorized
                    by the download site of each Game on a mobile device that you own or control for your use (the
                    “License”).</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.2 Certain
                    Restrictions</p><p dir="ltr"
                                                                                     className="CDt4Ke zfr3Q">The rights
                    granted to you in this Agreement are subject to the following restrictions: (a) you shall not
                    license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit
                    any Game; (b) you shall not modify, translate, adapt, merge, make derivative works of, disassemble,
                    decompile, reverse compile or reverse engineer any part of Games, except to the extent the foregoing
                    restrictions are expressly prohibited by applicable law; (c) you shall not access any Game in order
                    to build a similar or competitive service or application; (d) except as expressly stated herein, no
                    part of any Game may be copied, reproduced, distributed, republished, downloaded, displayed, posted
                    or transmitted in any form or by any means, or (e) you shall not remove or destroy any copyright
                    notices or other proprietary markings contained on or in any Game. Any future release, update, or
                    other addition to the functionality of any Game (including in-app purchases, additional levels, and
                    gameplay enhancements) shall be subject to the terms of this Agreement, unless otherwise provided in
                    terms associated with such addition. All copyright and other proprietary notices on any Game content
                    must be retained on any copies.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.3
                    Local Laws</p><p dir="ltr"
                                                                                   className="CDt4Ke zfr3Q">You are
                    solely responsible for compliance with all applicable laws, including without limitation export and
                    import regulations.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.4
                    Modification</p><p dir="ltr"
                                                                                     className="CDt4Ke zfr3Q">FN
                    reserves the right, at any time, to modify, suspend, or discontinue the Games or any part thereof
                    with or without notice. You agree that FN will not be liable to you or to any third party for any
                    modification, suspension, or discontinuance of any Game or any part thereof.</p><p dir="ltr"
                                                                                                       className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.5 Ownership</p><p dir="ltr"
                                                                                      className="CDt4Ke zfr3Q"><br/></p>
                <p dir="ltr" className="CDt4Ke zfr3Q">Games provided to you are licensed to you and not sold. FN (and
                    its licensors, where applicable) own all right, title and interest, including all related
                    intellectual property rights, in and to all Games, excluding your User Content (defined below). This
                    Agreement is not a sale and does not convey to you any rights of ownership in or related to any
                    Game. The FN name, logo, and the product names associated with the Games belong to FN (or its
                    licensors, where applicable), and no right or license is granted to use them by implication,
                    estoppel or otherwise. FN (and its licensors, where applicable) reserve all rights not granted in
                    this Agreement.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>2.6
                    Ads</p><p dir="ltr" className="CDt4Ke zfr3Q">When you
                    start or stop a Game, it may display an ad. During gameplay, banners, rewarded videos and/or
                    interstitial ads may be displayed.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem'}}>3.
                    User Content</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>3.1 User
                    Content</p><p dir="ltr" className="CDt4Ke zfr3Q">“User
                    Content” of a user means any and all content that such user uploads, distributes, or otherwise
                    provides via any Game. You are solely responsible for your User Content. You assume all risks
                    associated with the use of your User Content, including any reliance on its accuracy, completeness
                    or usefulness by others, or any disclosure of your User Content that makes you or any third party
                    personally identifiable. You hereby represent and warrant that your User Content does not violate
                    the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in
                    any way provided, sponsored or endorsed by FN. FN is not obligated to backup any User Content and
                    User Content may be deleted at any time. You are solely responsible for creating backup copies of
                    your User Content if you desire.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>3.2
                    License</p><p dir="ltr" className="CDt4Ke zfr3Q">By
                    uploading, distributing, or otherwise using your User Content with any Game, you automatically
                    grant, and you represent and warrant that you have the right to grant to FN an irrevocable,
                    non-exclusive, royalty-free and fully paid worldwide license with the right to grant sublicenses to
                    reproduce, distribute, publicly display, publicly perform, prepare derivative works of, incorporate
                    into other works, and otherwise use your User Content, solely to display your User Content in any
                    Game.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>3.3
                    Feedback</p><p dir="ltr" className="CDt4Ke zfr3Q">If
                    you provide FN any feedback or suggestions (“Feedback”), you hereby assign to FN all rights in the
                    Feedback and agree that FN shall have the right to use such Feedback and related information in any
                    manner it deems appropriate. FN will treat any Feedback you provide to FN as non-confidential and
                    non-proprietary. You agree that you will not submit to FN any information or ideas that you consider
                    to be confidential or proprietary.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>3.4
                    Acceptable Use Policy</p><p dir="ltr"
                                                                                              className="CDt4Ke zfr3Q">The
                    following sets forth FN’s – “Acceptable Use Policy”:</p><p dir="ltr" className="CDt4Ke zfr3Q"><br/>
                </p><p dir="ltr" className="CDt4Ke zfr3Q">You agree not to use any Game to upload, distribute, or
                    otherwise use any User Content (a) that violates any third-party right, including any copyright,
                    trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other
                    intellectual property or proprietary right; (b) that is tortious, trade libelous, defamatory, false,
                    or intentionally misleading, (c) that is harassing, abusive, threatening, harmful, vulgar, obscene,
                    or offensive, or that contains pornography, nudity, or graphic or gratuitous violence, or that
                    promotes violence, racism, discrimination, bigotry, hatred, or physical harm of any kind against any
                    group or individual, or is otherwise objectionable, (d) that is harmful to minors in any way; (e)
                    that constitutes unsolicited or unauthorized advertising, promotional materials, junk mail, spam,
                    chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether
                    commercial or otherwise; or (f) that violates of any law, regulation, or contractual
                    obligations.</p><p dir="ltr" className="CDt4Ke zfr3Q">You agree not to use any Game to (a) upload or
                    distribute any computer viruses, worms, malicious code, or any software intended to damage or alter
                    a computer system or data; (b) collect information or data regarding other users, including email
                    addresses, without their consent (e.g., using harvesting bots, robots, spiders, or scrapers); (c)
                    disable, overly burden, impair, or otherwise interfere with servers or networks connected to Games
                    (e.g., a denial of service attack); (d) attempt to gain unauthorized access to the Site or Game or
                    servers or networks connected to Games (e.g., through password mining); or (e) interfere with
                    another user’s use and enjoyment of any Game.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>3.5
                    Enforcement</p><p dir="ltr"
                                                                                    className="CDt4Ke zfr3Q">We reserve
                    the right (but has no obligation) to review any User Content in our sole discretion. We may remove
                    or modify your User Content at any time for any reason in our sole discretion with or without notice
                    to you.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.
                    Term and Termination</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.1.
                    This User Agreement runs for an indefinite term.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.2. Either Party may terminate this Agreement at any time by
                    giving 14-days notice (written or electronic communication, e.g. via email, required). You may also
                    terminate the User Agreement with immediate effect by deleting the Game from your device or removing
                    the Game from your Facebook apps.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.3
                    If we have reasonable grounds to believe that you are in material breach of these Terms of Service,
                    we may suspend your access to our Game and/or terminate this User Agreement. Your breach of Sec. 2
                    or 3.4. shall be considered a material breach.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.4 Either Party may terminate the User Agreement for cause
                    without giving notice. The grounds for such termination for cause include material breach of these
                    Terms of Service.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.5
                    Upon expiry, you will no longer be able to access the Game that was the subject matter of the User
                    Agreement. Your in-game progress and any other Game-related data will be deleted. Only if FN
                    continues to operate the Game, you may again download the Game or access the Game on Facebook
                    whereby a new User Agreement will start to run. However, any game-related data cannot be
                    restored.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.6. We may
                    (a) suspend your rights to use any Game, and/or any related services or (b) terminate this Agreement
                    at any time for any reason at our sole discretion with or without notice to you, including if we in
                    good faith believe you have violated the Acceptable Use Policy or any other provision of this
                    Agreement. Without limiting the foregoing, FN reserves the right to terminate this Agreement with
                    any user who repeatedly infringes third-party copyright rights upon prompt notification to FN by the
                    copyright owner or the copyright owner’s legal agent.</p><p dir="ltr" className="CDt4Ke zfr3Q"><br/>
                </p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>4.7. Upon termination of this Agreement, your right to use the
                    Game will automatically terminate immediately. You understand that any termination may involve
                    deletion of your User Content associated therewith from our live databases. FN will not have any
                    liability whatsoever to you for any termination of this Agreement, including deletion of your User
                    Content. Even after this Agreement is terminated, the following provisions of this Agreement will
                    remain in effect: Sections 2.2, 2.3, 2.4, 2.5, 3, 4.4, 5, 6, 7, 8, 9, 10, and 11.</p><p dir="ltr"
                                                                                                            className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>5. Indemnity</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">You agree to defend, indemnify and hold harmless FN
                    (and its suppliers) from and against any claims, suits, losses, damages, liabilities, costs, and
                    expenses (including reasonable attorneys’ fees) brought by third parties resulting from or relating
                    to: (i) your use of any Game, (ii) your User Content, or (iii) your violation of this Agreement. FN
                    reserves the right, at your expense, to assume the exclusive defense and control of any matter for
                    which you are required to indemnify FN and you agree to cooperate with our defense of these claims.
                    You agree not to settle any matter without the prior written consent of FN. FN will use reasonable
                    efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q"><br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>6. Third
                    Parties</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>6.1
                    Application Stores</p><p dir="ltr"
                                                                                           className="CDt4Ke zfr3Q">You
                    acknowledge and agree that the availability of the Game is dependent on the third party from which
                    you received Game, e.g., the Apple iPhone or Android stores (“Application Store”). You acknowledge
                    that this Agreement is between you and FN and not with the Application Store. The Application Store
                    is not responsible for the Game, the content thereof, maintenance, support services, and warranty
                    therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or
                    intellectual property infringement). You agree to pay all fees charged by the Application Store in
                    connection with Game (if any). You agree to comply with, and your license to use Game is conditioned
                    upon your compliance with, all applicable third-party terms of the agreement (e.g., the Application
                    Store’s terms and policies) when using a Game. You acknowledge that the Application Store (and its
                    subsidiaries) are third-party beneficiaries of this Agreement and will have the right to enforce
                    this Agreement.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>6.2
                    Third-Party Services</p><p dir="ltr"
                                                                                             className="CDt4Ke zfr3Q">FN
                    may permit certain third-party applications (like leaderboards, game networks) to provide content
                    through the Game (“Third Party Services”). The Game may be used to send content provided by the
                    Third-Party Service between users who have the Third-Party Service installed on their device. When
                    you do so, FN will share information with the Third-Party Service as described in the FN Privacy
                    Policy. FN is not responsible for and does not control Third-Party Services. FN provides these
                    Third-Party Services only as a convenience to you. FN has no obligation to review or monitor and
                    does not approve, endorse, or make any representations or warranties with respect to Third-Party
                    Services. You use all Third-Party Services at your own risk. When you access a Third-Party Service,
                    the applicable third party’s terms and policies apply, including the third party’s privacy policies.
                    You should make whatever investigation you feel necessary or appropriate before proceeding with any
                    transaction in connection with any Third-Party Service.</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>6.3 Other Users</p><p dir="ltr"
                                                                                        className="CDt4Ke zfr3Q"><br/>
                </p><p dir="ltr" className="CDt4Ke zfr3Q">A Game may contain User Content provided by other users of the
                    Game. FN is not responsible for and does not control the User Content. FN has no obligation to
                    review or monitor, and does not approve, endorse, or make any representations or warranties with
                    respect to User Content. You use all User Content and interact with other users at your own risk.
                    Your interactions with other users are solely between you and the other user and we are under no
                    obligation to become involved. You agree that FN will not be responsible for any liability incurred
                    as the result of any such interactions.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>6.4 Release</p><p
                    dir="ltr" className="CDt4Ke zfr3Q">You hereby irrevocably and unconditionally release and forever
                    discharge FN (and its suppliers) from any and all claims, demands, and rights of action, whether now
                    known or unknown, which relates to any interactions with, or act or omission of, any Third Party
                    Service, other Games users, or Third Party Advertisers. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY
                    WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL
                    RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXISTS IN HIS OR
                    HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
                    AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>7. Disclaimers</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>7.1 GAMES ARE PROVIDED “AS-IS” AND AS AVAILABLE AND FN (AND ITS
                    SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                    INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. FN (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT ANY
                    GAME: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                    ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.</p><p dir="ltr"
                                                                                                         className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>7.2 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                    IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p><p dir="ltr"
                                                                                           className="CDt4Ke zfr3Q"><br/>
                </p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>8. Limitation on Liability</p><p dir="ltr"
                                                                                           className="CDt4Ke zfr3Q"><br/>
                </p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>8.1 IN NO EVENT SHALL FN (OR ITS SUPPLIERS) BE LIABLE TO YOU
                    OR ANY THIRD PARTY FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                    SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR FN’S PRIVACY PRACTICES,
                    ANY GAME, EVEN IF FN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, ANY
                    GAME ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                    COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO
                    THE CONTRARY CONTAINED HEREIN, FN’S LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS
                    AGREEMENT OR FN’S PRIVACY PRACTICES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                    ACTION) WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU’VE PAID FN IN THE PRIOR 12 MONTHS (IF ANY).
                    IN NO EVENT SHALL FN’S SUPPLIERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS
                    AGREEMENT.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>8.2 SOME
                    JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL
                    RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.</p><p dir="ltr" className="CDt4Ke zfr3Q"><br/>
                </p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>9. Fees</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>9.1. In-app purchases.</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Funnii may license to you certain virtual goods to be
                    used within the Service and which you may purchase with real cash or which you may earn or redeem
                    via gameplay (“Virtual Items”). Virtual Items are licensed to you on limited, personal,
                    non-transferable, non-sublicensable and revocable basis and limited only for non-commercial use.</p>
                <p dir="ltr" className="CDt4Ke zfr3Q">Funnii may manage, control, modify or eliminate Virtual Items at
                    any time, with or without notice.</p><p dir="ltr" className="CDt4Ke zfr3Q">The transfer of Virtual
                    Items is prohibited, except where expressly authorized in the Services, if any.</p><p dir="ltr"
                                                                                                          className="CDt4Ke zfr3Q">Virtual
                    Items do not have an equivalent value in real cash and do not act as a substitute for real world
                    money. Neither <b>Funnii</b> nor any other person or entity has any obligation to exchange Virtual Items
                    for anything of value. <b>Funnii</b> has no liability for hacking or loss of your Virtual Items.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q">Price and availability of Virtual Items are subject to change
                    without notice.</p><p dir="ltr" className="CDt4Ke zfr3Q">Subject to mandatory legislation, all
                    purchases and redemptions of Virtual Items made through the Services are final and non-refundable.
                    You acknowledge and consent that the provision of Virtual Items for use in Services is a process
                    that commences immediately upon purchase and you forfeit your right of withdrawal once the
                    performance has started.</p><p dir="ltr" className="CDt4Ke zfr3Q">Subject to mandatory legislation,
                    you agree that <b>Funnii</b> is not required to provide a refund for Virtual Items for any reason, and that
                    you will not receive money or other compensation for unused Virtual Items, whether your loss of
                    license under these Agreement was voluntary or involuntary.</p><p dir="ltr"
                                                                                      className="CDt4Ke zfr3Q">If you
                    request that your personal data to be erased as specified in <b>Funnii</b> ́s Privacy Policy, you will
                    permanently and without a right to a refund lose all your Virtual Items as <b>Funnii</b> can no longer
                    associate such Virtual Items with you.</p><p dir="ltr" className="CDt4Ke zfr3Q">YOU ACKNOWLEDGE THAT
                    FN IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER
                    COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN YOU DELETE YOUR ACCOUNT OR WHEN YOU CEASE TO USE THE
                    GAME.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>9.2. Subscriptions</p><p dir="ltr"
                                                                                            className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Some parts of the Service are billed on a subscription
                    basis (“Subscription(s)”). Subscription provides access to dynamic content or services from within
                    the Game on an ongoing basis. Subscriptions may be available at different fees chargeable for a set
                    period of time specified in the Game (“Subscription Period”). Payment will be taken from Your iTunes
                    or Google Play account (“Account”) when You confirm the Subscription by available confirmation tools
                    of the Game.</p><p dir="ltr" className="CDt4Ke zfr3Q">Trial Subscription is offered free of charge
                    for certain period of time from activation specified in the relevant offer in the Game. If You do
                    not cancel the Subscription within such period, Subscription Fee shall be taken from Your Account
                    when the trial period expires. Please note that Your Subscription begins immediately after the
                    activation of a trial Subscription, not after the seven-days trial period. You may cancel a
                    subscription during its free trial period using the Subscription setting of Your Account. FN can not
                    cancel your free-trial subscription if it has already been activated.</p><p dir="ltr"
                                                                                                className="CDt4Ke zfr3Q">Subscription
                    is automatically renewable, unless You turn it off at least 24 hours before the expiry of the
                    current Subscription. Subscription Fee shall be taken from Your Account during 24 hours preceding
                    the expiry of the current Subscription. In case Subscription Fee cannot be taken from Your Account
                    due to absence of monetary funds, invalidity of credit card or for any other reasons, Your
                    Subscription is automatically cancelled.</p><p dir="ltr" className="CDt4Ke zfr3Q">Once You have
                    bought a Subscription, You can manage it and switch off automatic renewal at any time after the
                    purchase in Your Account setting. You cannot cancel Your current Subscription if it has already been
                    activated.</p><p dir="ltr" className="CDt4Ke zfr3Q">Except when required by law, paid Subscription
                    Fees are non-refundable.</p><p dir="ltr" className="CDt4Ke zfr3Q">Funnii in its sole discretion and
                    at any time may modify the Subscription Fee. Any Subscription Fee change will become effective at
                    the end of the current Subscription period. You will be provided a reasonable prior notice of any
                    change in Subscription Fee. If You do not take action to agree to the increase in Subscription Fee,
                    Your Subscription shall expire at the end of the current Subscription period.</p><p dir="ltr"
                                                                                                        className="CDt4Ke zfr3Q">You
                    acknowledge and agree that all billing and transaction processes are handled by App Store or Google
                    Play, from which platform You downloaded the App, and are governed by their terms and conditions. If
                    You have any payment related issues, then You need to contact App Store or Google Play directly.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>10. Miscellaneous</p><p dir="ltr" className="CDt4Ke zfr3Q"><br/>
                </p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>10.1. Changes to this Agreement</p><p dir="ltr"
                                                                                                className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">This Agreement is subject to occasional revision, and
                    if we make any substantial changes, we may notify you by prominently posting notice of the changes
                    on our Site. Any changes to this Agreement will be effective upon the earlier of thirty (30)
                    calendar days following our dispatch of an email notice to you (if applicable) or thirty (30)
                    calendar days following our posting of notice of the changes on our Site. These changes will be
                    effective immediately for new users of our Games. Continued use of our Games following notice of
                    such changes shall indicate your acknowledgement of such changes and agreement to be bound by the
                    terms and conditions of such changes. The date on which the latest update was made is indicated at
                    the top of this document. We recommend that you print a copy of this Agreement for your reference
                    and revisit this webpage from time to time to ensure you are aware of any changes.</p><p dir="ltr"
                                                                                                             className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>10.2. Notice</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">Any notice provided to FN pursuant to this Agreement
                    should be sent to contact@funnii.net</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>10.3.
                    Severability</p><p dir="ltr"
                                                                                     className="CDt4Ke zfr3Q">If any
                    provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other
                    provisions of this Agreement will be unimpaired, and the invalid or unenforceable provision will be
                    deemed modified so that it is valid and enforceable to the maximum extent permitted by law.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q"><br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>10.4. Entire
                    Agreement</p><p dir="ltr"
                                                                                  className="CDt4Ke zfr3Q">This
                    Agreement is the final, complete and exclusive agreement between you and FN with respect to the
                    subject matter hereof (including all Games) and supersedes and merges all prior discussions and
                    agreements between the parties with respect to such subject matters (including any prior End-User
                    License Agreements and Terms of Service or Privacy Policy). Our failure to exercise or enforce any
                    right or provision of this Agreement shall not operate as a waiver of such right or provision. The
                    section titles in this Agreement are for convenience only and have no legal or contractual effect.
                    The word “including” means “including without limitation”. Your relationship to FN is that of an
                    independent contractor, and neither party is an agent or partner of the other. This Agreement, and
                    your rights and obligations herein, may not be assigned by you without FN’s prior written consent,
                    and any attempted assignment in violation of the foregoing will be null and void. FN may assign this
                    Agreement in connection with a merger, acquisition, reorganization or sale of all or substantially
                    all of its assets, or other operation of law, without your consent. The terms of this Agreement
                    shall be binding upon assignees.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.
                    Apple Application Store Additional Terms and Conditions</p><p dir="ltr" className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">The following additional terms and conditions apply to
                    you if you are using a Game from the Apple Application Store. To the extent the other terms and
                    conditions of this Agreement are less restrictive than, or otherwise conflict with, the terms and
                    conditions of this Section 10, the more restrictive or conflicting terms and conditions in this
                    Section 10 apply, but solely with respect to Games from the Apple Application Store.</p><p dir="ltr"
                                                                                                               className="CDt4Ke zfr3Q">
                    <br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.1 Acknowledgement</p><p dir="ltr"
                                                                                             className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">FN and you acknowledge that this Agreement is
                    concluded between FN and you only, and not with Apple, and FN, not Apple, is solely responsible for
                    Game and the content thereof. To the extent this Agreement provides for usage of the rules for Game
                    that are less restrictive than the Usage Rules set forth for Game in, or otherwise is in conflict
                    with, the Application Store Terms of Service, the more restrictive or conflicting Apple term
                    applies.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.2
                    Scope of License</p><p dir="ltr"
                                                                                         className="CDt4Ke zfr3Q">The
                    license granted to you for Game is limited to a non-transferable license to use Game on an iOS
                    Product that you own or control and as permitted by the Usage Rules set forth in the Application
                    Store Terms of Service.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.3
                    Maintenance and Support</p><p dir="ltr"
                                                                                                className="CDt4Ke zfr3Q">FN
                    is solely responsible for providing any maintenance and support services with respect to Game, as
                    specified in this Agreement (if any) or as required under applicable law. FN and you acknowledge
                    that Apple has no obligation whatsoever to furnish any maintenance and support services with respect
                    to Game.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.4
                    Warranty</p><p dir="ltr" className="CDt4Ke zfr3Q">FN
                    is solely responsible for any product warranties, whether expressed or implied by law, to the extent
                    not effectively disclaimed. In the event of any failure of Game to conform to any applicable
                    warranty, you may notify Apple, and Apple may refund the purchase price for Game to you; and to the
                    maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever
                    with respect to Game, and any other claims, losses, liabilities, damages, costs or expenses
                    attributable to any failure to conform to any warranty will be FN’s sole responsibility.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q"><br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.5 Product
                    Claims</p><p dir="ltr" className="CDt4Ke zfr3Q">FN and
                    you acknowledge that FN, not Apple, is responsible for addressing any claims of you or any third
                    party relating to Game or your possession and/or use of Game, including, but not limited to: (i)
                    product liability claims; (ii) any claim that Game fails to conform to any applicable legal or
                    regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
                    This Agreement does not limit FN’s liability to you beyond what is permitted by applicable law.</p>

                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.6 Intellectual
                    Property Rights</p><p dir="ltr"
                                                                                        className="CDt4Ke zfr3Q">FN and
                    you acknowledge that, in the event of any third-party claim that Game or your possession and use of
                    Game infringes the third party’s intellectual property rights, FN, not Apple, will be solely
                    responsible for the investigation, defense, settlement and discharge of any such intellectual
                    property infringement claims.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.7
                    Legal Compliance</p><p dir="ltr"
                                                                                         className="CDt4Ke zfr3Q">You
                    represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
                    embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and
                    (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p><p
                    dir="ltr" className="CDt4Ke zfr3Q"><br/></p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.8 Developer Name
                    and Address</p><p dir="ltr"
                                                                                    className="CDt4Ke zfr3Q">FN’s
                    contact information for any end-user questions, complaints or claims with respect to Game is set
                    forth in Section 9.2.</p>
                <p style={{    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginBottom: '2rem'}}>11.9
                    Third-Party Terms of Agreement</p><p dir="ltr"
                                                                                                       className="CDt4Ke zfr3Q">You
                    must comply with applicable third-party terms of agreement when using a Game.</p><p dir="ltr"
                                                                                                        className="CDt4Ke zfr3Q">
                    <br/></p><p dir="ltr" className="CDt4Ke zfr3Q">11.10 Third-Party Beneficiary</p><p dir="ltr" className="CDt4Ke zfr3Q">FN and you acknowledge and agree that Apple, and
                    Apple’s subsidiaries, are third-party beneficiaries of this Agreement, and that, upon your
                    acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be
                    deemed to have accepted the right) to enforce this Agreement against you as a third-party
                    beneficiary thereof.</p></div>

        </div>
);
}

export default CompanyPage;